const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_GRKlTYjgb",
    "aws_cognito_identity_pool_id": "eu-west-1:041b1b3c-c6a0-4c56-8d4d-002a45c5a57b",
    "aws_user_pools_web_client_id": "5vjrqisbqg6edpfff4t1hlk3nr",
    "aws_appsync_graphqlEndpoint": "https://yz5sfocrcff47nuopemljg2tdu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "magicmakerstack-magicstorage584335d1-1vm4ss92ry4pd",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "magic",
            "endpoint": "https://2or42usm9j.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "logger",
            "endpoint": "https://sb36xcrwx4.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "keys",
            "endpoint": "https://58nocgldz5.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "reporter",
            "endpoint": "https://udpnn9eiw0.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ]
};

export default awsmobile;